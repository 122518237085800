// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Ganesh",
  middleName: "",
  lastName: "Kaila",
  message: " Passionate to learn and contribute to the technology. ",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/ganeshkaila",
    },
    {
      image: "fa-facebook",
      url: "https://www.facebook.com/ganeshkaila",
    },
    {
      image: "fa-instagram",
      url: "https://www.instagram.com/ganeshkaila/",
    },
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/ganeshkaila/",
    },
    {
      image: "fa-twitter",
      url: "https://www.twitter.com/ganeshkaila/",
    },
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/ganeshkaila.jpeg"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/ganeshkaila.jpeg"),
  imageSize: 375,
  message:
    "I'm Ganesh Kaila, working as a Lead Cloud Architect in Searce, Inc. A Cloud Enthusiast, learner and contributor to the technology.",
  // resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: true,
  heading: "Personal Projects",
  gitHubUsername: "ganeshkaila", //i.e."johnDoe12Gh"
  reposLength: 4,
  specificRepos: [],
};

// Leadership SECTION
const leadership = {
  show: false,
  heading: "Leadership",
  message:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Et magnis dis parturient montes nascetur ridiculus mus mauris. In nisl nisi scelerisque eu ultrices vitae auctor. Mattis nunc sed blandit libero volutpat sed cras ornare. Pulvinar neque laoreet suspendisse interdum consectetur libero.",
  images: [
    {
      img: require("../editable-stuff/ganeshkaila.jpeg"),
      label: "First slide label",
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum."
    },
    {
      img: require("../editable-stuff/ganeshkaila.jpeg"),
      label: "Second slide label",
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum."
    },
  ],
  imageSize: {
    width: "615",
    height: "450"
  }
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Skills",
  hardSkills: [
    { name: "Google Cloud Platform", value: 90 },
    { name: "Kubernetes", value: 85 },
    { name: "Terraform", value: 80 },
    { name: "Ansible", value: 70 },
    { name: "Python", value: 65 },
    { name: "Docker", value: 55 },
    { name: "NodeJS", value: 55 },
    { name: "GoLang", value: 40 },
    // { name: "HTML/CSS", value: 55 },
    // { name: "C#", value: 80 },
  ],
  // softSkills: [
  //   { name: "Goal-Oriented", value: 80 },
  //   { name: "Collaboration", value: 90 },
  //   { name: "Positivity", value: 75 },
  //   { name: "Adaptability", value: 85 },
  //   { name: "Problem Solving", value: 75 },
  //   { name: "Empathy", value: 90 },
  //   { name: "Organization", value: 70 },
  //   { name: "Creativity", value: 90 },
  // ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Get In Touch",
  message:
    "Please feel free to email me at",
  email: "kailaganesh07@gmail.com",
};

const experiences = {
  show: true,
  heading: "Experience",
  data: [
    {
      role: 'Lead Cloud Architect',// Here Add Company Name
      companylogo: require('../assets/img/searce.png'),
      date: 'October 2022 – Present',
    },
    {
      role: 'Cloud Architect',// Here Add Company Name
      companylogo: require('../assets/img/searce.png'),
      date: 'October 2021 – September 2022',
    },
    {
      role: 'Senior Cloud Consultant',// Here Add Company Name
      companylogo: require('../assets/img/deloitte.png'),
      date: 'June 2021 – September 2021',
    },
    {
      role: 'Cloud Consultant',// Here Add Company Name
      companylogo: require('../assets/img/deloitte.png'),
      date: 'June 2018 – May 2021',
    },
    {
      role: 'Senior Software Engineer',// Here Add Company Name
      companylogo: require('../assets/img/vedams.png'),
      date: 'February 2018 – May 2018',
    },
    {
      role: 'Software Engineer',// Here Add Company Name
      companylogo: require('../assets/img/vedams.png'),
      date: 'August 2014 – January 2018',
    },
    {
      role: 'Embedded Engineer',// Here Add Company Name
      companylogo: require('../assets/img/trylogic.png'),
      date: 'November 2012 – April 2014',
    },
  ]
}

// Blog SECTION
// const blog = {
//   show: false,
// };

export { navBar, mainBody, about, repos, skills, leadership, getInTouch, experiences };
